import { IRowNode } from '@ag-grid-community/core';
import { formatDate } from '@angular/common';
import { Period } from '@hl7fhir';

/**
 * Formats the FHIR DateTime data type into a human friendly format
 * @param {string} date the FHIR date time
 * @returns {string} human friendly formatted date and/or time representation
 */
export function formatLocaleDate(date: string | undefined): string {
  if (!date) {
    return '';
  }

  const periodDateWords = [
    `${$localize`:@@fhir.Period.start:From`}`,
    `${$localize`:@@fhir.Period.lowerTextOfEnd:to`}`,
    `${$localize`:@@fhir.Period.upperTextOfEnd:To`}`,
  ];

  for (const word of periodDateWords) {
    if (date.includes(word)) {
      return date;
    }
  }

  try {
    // Only render date if there is no time present
    let renderFormat = 'dd MMM yyyy HH:mm';
    if (!date.includes('T')) {
      renderFormat = 'dd MMM yyyy';
    }
    const parsedDate = Date.parse(date);
    // If there is timezone information render in local time
    return formatDate(parsedDate, renderFormat, $localize.locale ?? 'en-US');
  } catch {
    return date;
  }
}

/**
 * Returns the start of the given day.
 * The time is set to 00:00:00.000.
 * @param date The date for which to get the start of the day. If no date is provided, the current date is used.
 * @returns {Date} The start of the given day.
 */
export function getStartOfDay(date: Date = new Date()): Date {
  const startOfGivenDay = new Date(date);
  startOfGivenDay.setHours(0, 0, 0, 0);

  return startOfGivenDay;
}

/**
 * Returns the end of the given day.
 * The time is set to 23:59:59.999.
 * @param date The date for which to get the end of the day. If no date is provided, the current date is used.
 * @returns {Date} The end of the given day.
 */
export function getEndDay(date: Date = new Date()): Date {
  const endOfGivenDay = new Date(date);
  endOfGivenDay.setHours(23, 59, 59, 999);

  return endOfGivenDay;
}

/**
 * Returns the first day (Monday) of the week for a given date.
 * @param {Date} date The date for which to get the first day of the week. If no date is provided, the current date is used.
 * @returns {Date} The first day of the week.
 */
export function getFirstDayOfWeek(date: Date = new Date()): Date {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  const firstDayOfWeek = new Date(date.setDate(diff));
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

/**
 * Returns the first day of the month for the given date.
 * @param {Date} date The date for which to get the first day of the month.  If no date is provided, the current date is used.
 * @returns {Date} The first day of the month.
 */
export function getFirstDayOfMonth(date: Date = new Date()): Date {
  const givenDay = new Date(date);
  const firstDayOfMonth = new Date(givenDay.getFullYear(), givenDay.getMonth(), 1);

  return firstDayOfMonth;
}

/**
 * Returns the first day of the year for the given date.
 * @param {Date} date The date for which to get the first day of the year. If no date is provided, the current date is used.
 * @returns {Date} The first day of the year.
 */
export function getFirstDayOfYear(date: Date = new Date()): Date {
  return new Date(date.getFullYear(), 0, 1);
}

/**
 * Returns the current date in the format 'YYYY-MM-DD'.
 *
 * @returns {string} The current date.
 */
export function getCurrentDate(): string {
  return new Date().toISOString().split('T')[0];
}

/**
 * Returns the current time in the format 'HHMM'.
 *
 * @returns {string} The current time.
 */
export function getCurrentTime(): string {
  const date = new Date();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}${minutes}`;
}

/**
 * Returns the current date in the format 'DD MMM YYYY',
 * with the month in letters (e.g. '04 Feb 2025').
 *
 * @returns {string} The formatted current date.
 */
export function getCurrentDateWithMonth(): string {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = now.toLocaleString(`${$localize.locale}`, { month: 'short' });
  const year = now.getFullYear();

  return `${day} ${month} ${year}`;
}

/**
 * Returns the max value of the day that can be used as input.
 *
 * @returns {string} The current date.
 */
export function getMaxDay(): string {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOString = new Date(Date.now() - tzoffset).toISOString();
  const dateString = localISOString.substring(0, 16);

  return dateString;
}

/**
 * Factory to create the comparator for AG Grid that handles dates.
 *
 * The given field will be used as the source property on the row data type.
 *
 * @param {string} field the field to use for sorting
 * @returns the AG Grid comparator
 */
export function createDateComparator<TValue, TData extends Record<string, any>>(field: string) {
  return function customComparator(
    valueA: TValue | null | undefined,
    valueB: TValue | null | undefined,
    nodeA: IRowNode<TData>,
    nodeB: IRowNode<TData>,
    // Part of the method signature from AG-Grid
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isDescending: boolean,
  ) {
    const nodeAValue = nodeA?.data?.[field];
    const nodeBValue = nodeB?.data?.[field];

    const extractDate = (value: any) => {
      if (value instanceof Date) {
        return value;
      }
      return null; // If value isn't a date
    };

    const dateA = extractDate(nodeAValue);
    const dateB = extractDate(nodeBValue);

    // Ensure null values are handled properly
    if (dateA === null && dateB === null) return 0;
    if (dateA === null) return -1;
    if (dateB === null) return 1;

    // Compare date values
    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
  };
}

/**
 * The FHIR period will be converted into a single date.
 *
 * For sorting we will use the start if it is not undefined, then we use the end as a fallback,
 * if that is also undefined we return undefined.
 *
 * @param {Period} period the FHIR Period datatype
 * @returns {Date | undefined } a Date that represents the sorting for the given period
 */
export function convertPeriodToSortable(period: Period | undefined): Date | undefined {
  if (period === undefined) {
    return undefined;
  }

  // We use the start date as the sorting date,
  // and fallback to the end if that is the only one set
  const dateString = period.start ?? period.end;
  if (dateString) {
    return new Date(dateString);
  }

  return undefined;
}

/**
 * FHIR dateTime datatype can be missing time information or even day and months.
 * For sorting we parse it to a full date again.
 *
 * Turning:
 * 2024 - 2024-01-01 00:00
 * 2024-02 - 2024-02-01 00:00
 * 2024-02-03 - 2024-02-03 00:00
 *
 * @param {string} dateTime the FHIR date time datatype in its string representation
 * @returns {Date | undefined} a parse sortable date.
 */
export function convertToSortable(dateTime: string | undefined): Date | undefined {
  if (dateTime) {
    return new Date(dateTime);
  }
  return undefined;
}
