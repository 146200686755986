import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { Urls } from '@globals';

@Component({
  standalone: true,
  selector: 'app-wdx-footer',
  templateUrl: './wdx-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WdxFooterComponent {
  @Input() compact = true;
  readonly appVersion = environment.version;

  readonly year = new Date().getFullYear();

  get termsOfUseLink(): string {
    return Urls.termsOfUse();
  }

  get privacyPolicyLink(): string {
    return Urls.privacyPolicy();
  }

  openLink(url: string): void {
    if (Capacitor.isNativePlatform()) {
      Browser.open({ url });
    } else {
      window.open(url);
    }
  }
}
